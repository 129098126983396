import React from "react";
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

interface SidebarItem {
  id: number;
  title: string;
  path: string;
  state: { page: string };
  toggle: boolean;
}

let SidebarData: SidebarItem[] = [];

if (window.localStorage.getItem("userType") === "admin") {
  SidebarData = [
    {
      id: 1,
      title: 'Αιτήσεις',
      path: '/dashboard',
      state: { page: 'dashboard' },
      toggle: true,
    },
    {
      id: 3,
      title: 'Ανακοινώσεις',
      path: '/add_announcement',
      state: { page: 'add_announcement' },
      toggle: true,
    },
    {
      id: 3,
      title: 'Προφίλ',
      path: '/user',
      state: { page: 'user' },
      toggle: true,
    }
  ];
} else {
  SidebarData = [
    {
      id: 1,
      title: 'ΑΡΧΙΚΗ',
      path: '/',
      state: { page: 'home' },
      toggle: true,
    },
    {
      id: 2,
      title: 'ΝΕΑ',
      path: '/announcements',
      state: { page: 'announcements' },
      toggle: true,
    },
    {
      id: 3,
      title: 'ΣΧΕΤΙΚΑ',
      path: '/about',
      state: { page: 'about' },
      toggle: true,
    },
    {
      id: 4,
      title: 'ΕΠΙΚΟΙΝΩΝΙΑ',
      path: '/contact',
      state: { page: 'contact' },
      toggle: true,
    },
  ];
}

export { SidebarData };
